/**
 * different options of the position for the user
 * @private
 */
import {AccountPosition} from "@/enum/AccountPosition.enum";
import i18n from "@/i18n";

export const ContactPositionItems = [
    { value: AccountPosition.MANAGER, label: i18n.t('GENERAL.POSITIONS.MANAGER').toString()},
    { value: AccountPosition.OWNER, label: i18n.t('GENERAL.POSITIONS.OWNER').toString()},
    { value: AccountPosition.EMPLOYEE, label: i18n.t('GENERAL.POSITIONS.EMPLOYEE').toString()},
    { value: AccountPosition.OTHER, label: i18n.t('GENERAL.POSITIONS.OTHER').toString()},
];

interface NavItemInterface {
    label: any,
    value: string
}