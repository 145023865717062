
import {namespace} from 'vuex-class';
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {alpha, minLength, required, integer} from 'vuelidate/lib/validators';
import {APPLICATION_STORE_NAME, ApplicationStoreMutations} from "@/store/application.store";
import Company from "@/models/Company";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import {Salutation} from "@/enum/Salutation.enum";
import {ContactPositionItems} from "@/misc/ContactPositionItems";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import AccountNotificationSubscriptionRepository from "@/api/repositories/AccountNotificationSubscriptionRepository";
import {AccountNotificationSubscription} from "@/enum/AccountNotificationSubscription.enum";
import RestauranteurRepository from "@/api/repositories/RestauranteurRepository";
import {AUTH_STORE_NAME, AuthStoreGetters, AuthStoreMutations} from "@/store/auth.store";
import Restaurateur from "@/models/Restaurateur";
import Address from "@/models/Address";
import Contact from "@/models/Contact";
import {AccountPosition} from "@/enum/AccountPosition.enum";
import {PhonePrefix, PhonePrefixes} from "@/enum/PhonePrefix.enum";
import {CountrySelectItems} from "@/enum/CountrySelect.enum";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);
const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    CustomStepperComponent: () => import(
      /* webpackChunkName: "CustomStepperComponent" */
      '@/components/Misc/CustomStepper.component.vue'
    ),
    EditCompanyPaymentComponent: () => import(
      /* webpackChunkName: "EditCompanyPaymentComponent" */
      '@/components/Settings/CompanySettings/EditCompanyPayment.component.vue'
    ),
    RegisterPaypalComponent: () => import(
      /* webpackChunkName: "EditCompanyPaymentComponent" */
      '@/components/Payment/RegisterPaypal.component.vue'
    )
  },
  mixins: [validationMixin],
  validations: {
    companyCopy: {
      name: {required, maxLength: maxLength(MaxLengthValidation.COMPANY_NAME)},
      legalName: {required, maxLength: maxLength(MaxLengthValidation.COMPANY_LEGAL_NAME)},
      address: {
        zip: {required, minLength: minLength(5), maxLength: maxLength(MaxLengthValidation.ZIP), integer},
        street: {required, maxLength: maxLength(MaxLengthValidation.STREET)},
        city: {required, maxLength: maxLength(MaxLengthValidation.CITY), alpha},
        country: {required, maxLength: maxLength(MaxLengthValidation.COUNTRY), alpha},
      },
      contact: {
        phone: {required, maxLength: maxLength(MaxLengthValidation.PHONE)},
        firstName: {required},
        lastName: {required},
        position: {required},
        title: {required}
      },
    },
    acceptedTermsOfService: {
      checked: value => value === true
    },
    // hasAddedPaypal: {
    //   checked: value => value === true
    // }
  }
})
export default class RegisterCompanyInformationComponent extends mixins(ErrorMessageHandlerMixin) {
  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_COMPANY)
  private setCurrentCompany!: (company: Company) => void;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user!: Restaurateur;

  @AuthStore.Mutation(AuthStoreMutations.SET_USER)
  private setUser!: (user: Restaurateur) => void;

  /**
   * validations for each step, is used to check for certain validation in each step
   * @private
   */
  private steps = [
    {validations: ['companyCopy.contact.firstName', 'companyCopy.contact.lastName', 'companyCopy.contact.position',
        'companyCopy.contact.title'], valid: true},
    {validations: ['companyCopy.name', 'companyCopy.legalName'], valid: true},
    // TODO: removed validation for paypal and payment view for now
    // {validations: ['hasAddedPaypal'], valid: true},
    // {validations: [], valid: true},
    {validations: ['companyCopy.address.street', 'companyCopy.address.zip', 'companyCopy.address.city',
        'companyCopy.contact.phone', 'acceptedTermsOfService'], valid: true}
  ]

  private acceptedTermsOfService = false;

  private acceptedNewsletter = false;

  private phonePrefix = PhonePrefix.GERMANY;

  /**
   * all available values that can be selected inside country selector
   * @private
   */
  private countrySelectItems = CountrySelectItems;

  private prefixItems = PhonePrefixes;

  /**
   * gets the link for the data privacy
   * @private
   */
  private get dataPrivacyLink() {
    return process.env.VUE_APP_DATA_PRIVACY;
  }

  /**
   * gets the link for the terms and conditions
   * @private
   */
  private get termsAndConditionsLink() {
    return process.env.VUE_APP_TERMS_AND_CONDITION;
  }

  /**
   * current step of the modal
   * @private
   */
  private currentStep = 0;

  /**
   * the maximum available step of the stepper
   * @private
   */
  private maxStepCount = 2;

  public companyCopy: Company = Company.parseFromObject({});

  private salutationItems = [
    {label: this.$t('GENERAL.MALE'), value: Salutation.MALE},
    {label: this.$t('GENERAL.FEMALE'), value: Salutation.FEMALE},
    {label: this.$t('GENERAL.DIVERSE'), value: Salutation.DIVERSE}
  ];

  private positionItems = ContactPositionItems;

  private async created() {
    try {
      this.isLoading = true;
      const {data} = await CompanyRepository.getCompanyById(this.$route.params.id);
      this.companyCopy = Company.parseFromObject({...data,
        address: Address.parseFromObject({...data.address, visible: true, country: 'Deutschland'}),
        contact: Contact.parseFromObject({...data.contact, title: data.contact.title ?? '',
          position: data.contact.position ?? AccountPosition.MANAGER})});

      // Check if the user already has finished the first two steps before navigating to stripe
      this.currentStep = this.hasFinishedFirstSteps() ? 2 : 0;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * flag that indicates if the component is loading
   * @private
   */
  private isLoading = false;

  /// Check if the user has set his details and company details already
  private hasFinishedFirstSteps() {
    const contact = this.companyCopy.contact;

    if (!contact) {
      return false;
    }

    return contact.firstName?.length > 0 && contact.lastName?.length > 0
        && contact.position?.length > 0 && this.companyCopy.legalName?.length > 0
        && this.companyCopy.name?.length > 0;
  }

  /**
   * is called when the save button is pressed, checks if a message was entered and edits the original news
   */
  private async saveData() {

    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if(this.$v.$invalid) {
      return;
    }


    try {
      this.isLoading = true;
      const payload: Company = Company.parseFromObject({...this.companyCopy});
      payload.contact.phone = this.phonePrefix + payload.contact.phone;
      const {data} = await CompanyRepository.updateCompany(this.$route.params.id, payload);
      const company = Company.parseFromObject(data);
      this.setCurrentCompany(company);

      // if newsletter was accepted, the user subscribes to notification channel
      if(this.acceptedNewsletter) {
        await AccountNotificationSubscriptionRepository.subscribeTo(AccountNotificationSubscription.NEWSLETTER);
      }

      this.dismiss(true);
    } catch(e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  private async updateBeforeStripe() {
    const {data} = await CompanyRepository.updateCompany(this.$route.params.id, this.companyCopy);
    const company = Company.parseFromObject(data);
    this.setCurrentCompany(company);

    const payload = Restaurateur.parseFromObject({
      ...this.user,
      firstName: this.companyCopy.contact.firstName,
      lastName: this.companyCopy.contact.lastName,
      title: this.companyCopy.contact.title,
      position: this.companyCopy.contact.position,
    });
    const response = await RestauranteurRepository.updateRestauranteur(payload);
    this.setUser(Restaurateur.parseFromObject(response.data));
  }

  /**
   * is called when the stepper is increased, increases the step and clamps it to maximum
   * @private
   */
  private async increaseStep() {
    // validates the current step to ensure the user made the correct inputs
    if(!await this.validateStep(this.steps[this.currentStep])) {
      this.$v.$touch();
      return;
    }

    this.$v.$reset();

    // Update once before reaching the payment method step
    if (this.currentStep == 1) {
      await this.updateBeforeStripe();
    }

    // checks if it is the final step and tries to create the news in the api
    if(this.currentStep === this.maxStepCount) {
      await this.saveData();
    }

    // goes to the next step
    if(this.currentStep < this.maxStepCount) this.currentStep++;
  }

  /**
   * validates a certain step by checking each validation parameter
   * @param step
   * @private
   */
  private async validateStep(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  /**
   * checks a specific type
   * @param type
   * @private
   */
  private async checkForm(type: string): Promise<boolean> {
    const inputValid = this.triggerValidation(type);
    const affectedStep = this.steps.find(step => step.validations.indexOf(type) != -1);
    affectedStep!.valid = inputValid;
    return inputValid;
  }

  /**
   * is called when the stepper is decreased, decreases the step and clamps it to minimum
   * @private
   */
  private decreaseStep() {
    if(this.currentStep > 0) this.currentStep--;
  }

  /**
   * closes the modal, sends flag in event that indicate if something has updated and the news should reload
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private hasAddedPaypal: boolean = false;
  private onPaypalAddedAsMethod(value: boolean) {
    this.hasAddedPaypal = value;
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
